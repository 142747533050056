import { createGlobalStyle, css } from 'styled-components';
import theme from './constants/theme';

/* tslint:disable:no-var-requires */

const mabry = (weightName: string) => css`
    src: url('${require(`./fonts/mabry-${weightName}-pro.eot`)}');
    src: url('${require(`./fonts/mabry-${weightName}-pro.eot`)}?#iefix') format('embedded-opentype'),
    url('${require(`./fonts/mabry-${weightName}-pro.woff2`)}') format('woff2'),
    url('${require(`./fonts/mabry-${weightName}-pro.woff`)}') format('woff'),
    url('${require(`./fonts/mabry-${weightName}-pro.ttf`)}') format('truetype');
    font-display: fallback;
`;

const input = (weightName: string) => css`
    src: url('${require(`./fonts/Input Mono ${weightName}.eot`)}');
    src: url('${require(`./fonts/Input Mono ${weightName}.eot`)}?#iefix') format('embedded-opentype'),
    url('${require(`./fonts/Input Mono ${weightName}.woff2`)}') format('woff2'),
    url('${require(`./fonts/Input Mono ${weightName}.woff`)}') format('woff');
    font-display: fallback;
`;

export const GlobalStyles = createGlobalStyle`
    html {
      font-size: 10px;
      overflow: -moz-scrollbars-none;
    }

    #__gatsby {
        min-height: 1px;
        min-width: 1px;
    }

    body {
        @media (${theme.mediaQueries.horizontal}) {
            overflow:hidden;
        }
        @media (${theme.mediaQueries.vertical}) {
            overflow-x:hidden;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
            -webkit-transition-delay: 9999s;
        }

        overscroll-behavior-x: auto;
        -webkit-overflow-scrolling: touch;
        margin: 0;
        padding: 0;
        font-family: 'Mabry', Helvetica, sans-serif;
        height: 100vh;
        font-size: 1.6rem;
        background: white;
    }

    body.disable-overscroll {
        overscroll-behavior-x: none;
    }

    body.disable-horizontal {
        max-width: 100%;
        overflow: visible;
        overflow-x:hidden;
        overscroll-behavior-x: contain;
        padding: 0;
        margin: 0;
    }

    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a,
    a:visited {
        text-decoration: none;
        color: currentColor;
    }

    .ReactModal__Body--open {
        overflow: hidden;
    }

    .ReactModal__Overlay {
        z-index: 11;
        opacity: 0;
        transition: opacity .25s;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

         &--after-open {
            opacity: 1;
        }

         &--before-close {
            transition-delay: 0s;
            opacity: 0;
        }
    }

    .ReactModal__Overlay.with-delay {
        transition-delay: .25s;
    }

    // override annoying inline styles
    .ReactModal__Content {
        top: 0 !important;
        left: 0 !important;
        bottom: 0 !important;
        right: 0 !important;
        padding: 0 !important;
        border-radius: 0 !important;
        border: 0 !important;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    @font-face {
        font-family: 'Mabry';
        font-weight: normal;
        font-style: normal;
        ${mabry('regular')};
        font-display: block;
    }

    @font-face {
        font-family: 'Mabry';
        font-weight: 500;
        font-style: normal;
        ${mabry('medium')};
        font-display: block;
    }

    @font-face {
        font-family: 'Mabry';
        font-weight: 700;
        font-style: normal;
        ${mabry('bold')};
        font-display: block;
    }

    @font-face {
        font-family: 'Mabry';
        font-weight: 300;
        font-style: normal;
        ${mabry('light')};
        font-display: block;
    }

    @font-face {
        font-family: 'Input Mono';
        font-weight: normal;
        font-style: normal;
        ${input('Regular')};
        font-display: fallback;
    }

    @font-face {
        font-family: 'Input Mono';
        font-weight: 700;
        font-style: normal;
        ${input('Bold')};
        font-display: fallback;
    }
`;
