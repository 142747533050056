import styled, { css } from 'styled-components';
import theme from '../../constants/theme';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';

export type BodyVariant = 'quoteHuge' | 'huge' | 'large' | 'default' | 'small' | 'tiny' | 'narration';

interface Props {
    variant?: BodyVariant;
}

export const quoteHugeCss = css`
    font-size: 6rem;
    line-height: 1.18;
    font-weight: 500;
    letter-spacing: -0.03em;

    @media (max-width: ${theme.mediaQueries.width.xxl}), (max-height: ${theme.mediaQueries.height.xxl}) {
        font-size: 6rem;
    }
    @media (max-width: ${theme.mediaQueries.width.xl}), (max-height: ${theme.mediaQueries.height.xl}) {
        font-size: 5.2rem;
    }
    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        font-size: 4.6rem;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        font-size: 4rem;
    }
    @media (max-width: ${theme.mediaQueries.width.s}), (max-height: ${theme.mediaQueries.height.s}) {
        font-size: 2.4rem;
    }
`;

export const hugeCss = css`
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 400;

    @media (max-width: ${theme.mediaQueries.width.xl}), (max-height: ${theme.mediaQueries.height.xl}) {
        font-size: 2.4rem;
        line-height: 3.2rem;
    }
    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        font-size: 2rem;
        line-height: 2.4rem;
    }
`;

export const largeCss = css`
    font-size: 3.2rem;
    line-height: 1.4;
    font-weight: 300;

    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        font-size: 2.8rem;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        font-size: 1.6rem;
        font-weight: 400;
    }
`;

export const defaultCss = css`
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.5;

    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        font-size: 2rem;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        font-size: 1.6rem;
        font-weight: 400;
    }
`;

export const smallCss = css`
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;

    @media screen and (min-width: ${theme.mediaQueries.width.l}) {
        font-size: 2rem;
        line-height: 2.4rem;
        font-weight: 300;
    }
`;

export const tinyCss = css`
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;

    @media screen and (min-width: ${theme.mediaQueries.width.s}) {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }
`;

export const narrationCss = css`
    font-family: 'Input Mono', monospace;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2.4rem;
    @media screen and (min-width: ${theme.mediaQueries.width.l}) {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }
`;

const getCss = (variant: BodyVariant) => {
    switch (variant) {
        case 'default':
            return defaultCss;
        case 'quoteHuge':
            return quoteHugeCss;
        case 'large':
            return largeCss;
        case 'huge':
            return hugeCss;
        case 'small':
            return smallCss;
        case 'tiny':
            return tinyCss;
        case 'narration':
            return narrationCss;
        default:
            // ensures this switch is exhaustive
            throw new UnreachableCaseError(variant);
    }
};

export default styled.p<Props>`
    ${({ variant = 'default' }) => getCss(variant)};
    white-space: pre-line;

    a {
        color: inherit;
    }
`;
