export default (min: number, max: number, type = 'px', minViewport = 375, maxViewport = 1200) => `
    calc(${min}${type} + (${max} - ${min}) * ((100vh - ${minViewport}px) / (${maxViewport} - ${minViewport})));
`;

export const cssCalcBetweenViewportHorizontal = (
    min: number,
    max: number,
    type = 'px',
    minViewport = 320,
    maxViewport = 899
) => `
    calc(${min}${type} + (${max} - ${min}) * ((100vw - ${minViewport}px) / (${maxViewport} - ${minViewport})));
`;
