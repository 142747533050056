import React, { FC, useContext } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { NotFoundFragment_linkList } from '../../entities/operationResults';
import { SiteContext } from '../../contexts/SiteContext';

interface Props {
    close: () => void;
    isHome?: boolean;
    menuItem?: NotFoundFragment_linkList;
}

const MobileMenuLink: FC<Props> = ({ close, isHome, children, menuItem }) => {
    const site = useContext(SiteContext);
    const redirectLink = menuItem && menuItem.redirectLink;
    const text = !isHome ? redirectLink && redirectLink.customText : 'Home';
    const uri = !isHome ? redirectLink && redirectLink.entry && `/${redirectLink.entry.uri}/` : '/';

    return uri && text ? (
        <TextWrapper>
            <MenuLink
                onClick={() => close()}
                to={site ? `/${site}${uri}` : `${uri}`}
                activeClassName={'active'}
                partiallyActive={!isHome && true}
            >
                {text}
            </MenuLink>
        </TextWrapper>
    ) : null;
};

const TextWrapper = styled.p`
    margin: 2rem 0;
    line-height: 1;
    font-size: 3.2rem;

    @media screen and (max-height: 500px) {
        font-size: 2.8rem;
        margin: 1rem 0;
    }

    @media screen and (max-width: 330px) {
        font-size: 2.8rem;
    }

    @media screen and (max-width: 275px) {
        font-size: 2.4rem;
    }

    @media screen and (min-width: ${theme.mediaQueries.width.m}) {
        font-size: 2.4rem;
    }
    @media screen and (min-width: ${theme.mediaQueries.width.xl}) {
        font-size: 3.2rem;
    }

    font-weight: 400;
    letter-spacing: 0;
    white-space: pre-line;
`;

const MenuLink = styled(Link)`
    &,
    &:visited {
        text-decoration: none;
        color: ${theme.colors.white};
    }

    &.active {
        color: ${theme.colors.gorse};
    }
`;

export default MobileMenuLink;
