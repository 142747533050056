import { WindowLocation } from '@reach/router';
import React, { FC, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { TransitionGroup } from 'react-transition-group';
import InputBoldEot from '../fonts/Input Mono Bold.eot';
import InputBoldWoff from '../fonts/Input Mono Bold.woff';
import InputBoldWoff2 from '../fonts/Input Mono Bold.woff2';
import { GlobalStyles } from '../globalStyling';
import useHorizontalScroll from '../hooks/useHorizontalScroll';
import scrollController from './controllers/ScrollController';
import Menu from './organisms/Menu';
import Page from './organisms/Page';
import Meta from './atoms/Meta';
import MenuQuery from '../entities/Menu';
import CampaignMenu from './organisms/CampaignMenu';
import ApolloWrapper from './organisms/ApolloWrapper';
import CookieBanner from './CookieBanner';
import { SiteContext } from '../contexts/SiteContext';

interface Props {
    location: WindowLocation;
    pageContext: {
        site: string;
        uri: string;
        menuData: MenuQuery;
        __typename: string;
    };
}

const App: FC<Props> = ({ children, location, pageContext }) => {
    if (typeof window !== 'undefined' && window.location.pathname.indexOf('//') >= 0) {
        const uri = window.location.pathname.replace(/(\/+)/g, '/');
        window.location.href = uri;
    }

    const isVertical = pageContext && pageContext.__typename === 'Craft_Campaign';

    useEffect(() => {
        if (typeof document !== 'undefined' && typeof window !== 'undefined') {
            const gridSupported = typeof document.createElement('div').style.grid === 'string';
            if (!gridSupported && !unsupportedBrowser) {
                window.location.href = '/unsupported-browser';
            }
        }
    }, [children]);

    const [disableOverscroll, setDisableOverscroll] = useState(true);
    useHorizontalScroll(location.pathname, (scrollLeft) => {
        // do not allow gestures if not at start of the page
        setDisableOverscroll(Math.round(scrollLeft) > 0);

        // tell scrollController the scrollX has changed
        scrollController.onWheel(scrollX);
    });

    const hideLinks = pageContext && (pageContext.uri === '404' || pageContext.uri === 'unsupported-browser');
    const unsupportedBrowser = pageContext && pageContext.uri === 'unsupported-browser';
    const menuData =
        pageContext && pageContext.menuData && pageContext.menuData.data && pageContext.menuData.data.craft;

    return (
        <ApolloWrapper>
            <SiteContext.Provider value={pageContext.site === 'nl' ? undefined : (pageContext.site as 'nl' | 'en')}>
                {isVertical ? (
                    <CampaignMenu />
                ) : (
                    <Menu hideLinks={hideLinks} blackLogo={unsupportedBrowser} menuData={menuData} />
                )}
                <Helmet>
                    <link rel="preload" href={InputBoldWoff2} as="font" type="font/woff2" crossOrigin="anonymous" />
                    <link rel="preload" href={InputBoldWoff} as="font" type="font/woff" crossOrigin="anonymous" />
                    <link rel="preload" href={InputBoldEot} as="font" type="font/eot" crossOrigin="anonymous" />
                    <html lang="nl" />
                    <body
                        className={
                            (isVertical && 'disable-horizontal') ||
                            (disableOverscroll && 'disable-overscroll') ||
                            undefined
                        }
                    />
                </Helmet>
                <Meta title="Oberon" description={'Oberon is een digitaal development bureau.'} />
                <GlobalStyles />
                <CookieBanner />
                <Page isVertical={isVertical}>
                    <TransitionGroup component={null}>{children}</TransitionGroup>
                </Page>
            </SiteContext.Provider>
        </ApolloWrapper>
    );
};
export default App;
