import { Link } from 'gatsby';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import { SiteContext } from '../../contexts/SiteContext';
import { NotFoundFragment_linkList } from '../../entities/operationResults';
import { monoCss } from '../ds/primitive/Typography';

interface Props {
    menuItem: NotFoundFragment_linkList;
}

const MenuLink: FC<Props> = ({ menuItem }) => {
    const site = useContext(SiteContext);
    const text = menuItem && menuItem.redirectLink && menuItem.redirectLink.customText;
    const entry = menuItem && menuItem.redirectLink && menuItem.redirectLink.entry;
    const uri = entry && entry.uri;

    return uri && text ? (
        <Item>
            <StyledLink to={site ? `/${site}/${uri}/` : `/${uri}/`} activeClassName={'active'} partiallyActive={true}>
                {text}
            </StyledLink>
        </Item>
    ) : null;
};

const Item = styled.li`
    @media screen and (${theme.mediaQueries.expandedMenu}) {}
    @media screen and (${theme.mediaQueries.collapsedMenu}) {
        display: none;
    }

    margin: 0 2.4rem;

    .active {
        font-weight: 600;
    }

    @media screen and (${theme.mediaQueries.vertical}) {
        margin: 0;

        .active {
            &::before {
                background: black;
            }
        }
    }
`;
const StyledLink = styled(Link)`
    &,
    &:visited {
        ${monoCss}
        position: relative;
        color: white;

        @media screen and (${theme.mediaQueries.vertical}) {
            color: black;
        }

        &:hover {
            font-weight: 600;
        }
        .active {
            font-weight: 600;
            &::before {
                content: '';
                position: absolute;
                top: 45%;
                margin-left: -2.5rem;
                height: 2px;
                width: 1.6rem;
                background: white;
                display: inline-block;

                @media (${theme.mediaQueries.vertical}) {
                    background: black;
                }
            }
        }
    }
`;
export default MenuLink;
