/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import App from './src/components/App';
import React from 'react';

if (typeof window !== 'undefined') {
    if (!('IntersectionObserver' in window)) {
        require('intersection-observer-polyfill/dist/IntersectionObserver.global.js');
    }
}

require('@babel/polyfill');

export const wrapPageElement = ({ element, props }) => (
    <App {...props}>{element}</App>
)