import { default as React, FunctionComponent } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

interface Props {
    title?: string;
    description?: string | null;
    image?: Array<{
        url2000: string | null;
    } | null> | null;
}

export const metaFragment = graphql`
    fragment MetaFragment on Craft_SeoSuperTableBlockType {
        seoDescription
        seoTitle
        seoImage {
            url2000: url(fit: { width: 2000, quality: 100 })
        }
    }
`;

const Meta: FunctionComponent<Props> = ({ title, description, image }) => {
    if (!title) {
        title = `${title} | Oberon`;
    } else {
        title = `${title}`;
    }

    const seoImage = image && image[0];

    return (
        <Helmet>
            <title>{`${title} | Oberon`}</title>
            <meta property="og:title" content={`${title} | Oberon`} />
            <meta name="twitter:title" content={`${title} | Oberon`} />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@OberonAmsterdam" />
            <meta name="author" content="Oberon Amsterdam, www.oberon.nl" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
            {description && <meta name="description" content={description} />}
            {description && <meta property="og:description" content={description} />}
            {description && <meta name="twitter:description" content={description} />}
            {seoImage && seoImage.url2000 && <meta name="twitter:image:src" content={seoImage.url2000} />}
            {seoImage && seoImage.url2000 && <meta name="og:image" content={seoImage.url2000} />}
        </Helmet>
    );
};

export default Meta;
