import { useEffect, useRef } from 'react';
import { Spring, SpringSystem } from 'rebound';

const getScrollLeft = () => document.documentElement.scrollLeft;

const setScrollLeft = (scrollLeft: number) => {
    document.documentElement.scrollLeft = scrollLeft;
};

const SCROLL_AMOUNT = 100;
const SCROLL_AMOUNT_STEP = SCROLL_AMOUNT * 10;

const useHorizontalScroll = (pathname: string, onChange?: (scrollLeft: number) => void) => {
    const springSystem = useRef<SpringSystem>();
    const spring = useRef<Spring>();
    useEffect(() => {
        springSystem.current = new SpringSystem();
        spring.current = springSystem.current!.createSpring();
        spring.current!.setCurrentValue(getScrollLeft());
        spring.current!.setOvershootClampingEnabled(true);
        spring.current!.addListener({
            onSpringUpdate(currSpring: Spring) {
                if (onChange) {
                    onChange(currSpring.getCurrentValue());
                }

                setScrollLeft(currSpring.getCurrentValue());
            },
        });

        return () => {
            spring.current!.destroy();
            springSystem.current!.removeAllListeners();
        };
    }, []);

    const wheel = (e: WheelEvent & { webkitDirectionInvertedFromDevice: boolean }) => {
        const angle = Math.atan2(e.deltaY, e.deltaX) / Math.PI;
        const forward = !(angle < 0.675 && angle > -0.375);
        let offset = Math.sqrt(Math.pow(e.deltaX, 2) + Math.pow(e.deltaY, 2));
        switch (e.deltaMode) {
            case WheelEvent.DOM_DELTA_LINE:
                offset *= SCROLL_AMOUNT;
                break;
            case WheelEvent.DOM_DELTA_PAGE:
                offset *= SCROLL_AMOUNT_STEP;
                break;
            default:
                break;
        }

        if (forward) {
            offset *= -1;
        }
        const distance = Math.max(getScrollLeft() + offset, 0);
        if (distance < document.body.scrollWidth - document.body.clientWidth && spring.current) {
            if (e.deltaMode === WheelEvent.DOM_DELTA_PIXEL) {
                // force spring to new value & don't animate
                spring.current.setCurrentValue(distance);
            } else {
                spring.current.setEndValue(distance);
            }
        }
    };

    const keydown = (e: KeyboardEvent & { target: HTMLUnknownElement }) => {
        let scrollValue = window.pageXOffset;
        let prevent = true;
        const targetType = e.target.nodeName.toLowerCase();
        switch (e.code) {
            case 'Home':
                scrollValue = 0;
                break;
            case 'End':
                scrollValue = document.body.scrollWidth - document.body.clientWidth;
                break;
            case 'ArrowUp':
                scrollValue -= SCROLL_AMOUNT;
                break;
            case 'ArrowDown':
                scrollValue += SCROLL_AMOUNT;
                break;
            case 'PageUp':
                scrollValue -= SCROLL_AMOUNT_STEP;
                break;
            case 'PageDown':
            case 'Space':
                if (targetType === 'textarea' || targetType === 'input') {
                    return;
                }
                scrollValue += SCROLL_AMOUNT_STEP;
                break;
            default:
                prevent = false;
                break;
        }

        if (prevent) {
            e.preventDefault();
        }

        if (spring.current) {
            spring.current.setEndValue(scrollValue);
        }
    };

    // bind window events
    useEffect(() => {
        if (typeof document === 'undefined') {
            return;
        }

        document.addEventListener('wheel', wheel);
        document.addEventListener('keydown', keydown);
        return () => {
            document.removeEventListener('wheel', wheel);
            document.removeEventListener('keydown', keydown);
        };
    }, []);
    // reset state to zero if a navigation occurred
    const shouldResetScroll = useRef(false);
    useEffect(() => {
        if (spring.current && shouldResetScroll.current) {
            spring.current.setCurrentValue(0);
        }
        shouldResetScroll.current = true;
    }, [pathname]);
};

export default useHorizontalScroll;
