import React, { useContext } from 'react';
import translations from '../constants/translations.json';
import { SiteContext } from '../contexts/SiteContext';

interface Props {
    id: keyof typeof translations;
}

type TranslationsJSON = Record<keyof typeof translations, { nl: string; en: string }>;

const Translate: React.FC<Props> = ({ id }) => {
    const site = useContext(SiteContext);
    return <>{(translations as TranslationsJSON)[id][site ?? 'nl']}</>;
};

export default Translate;
