import React from 'react';
import styled from 'styled-components';

const PhoneNumber = () => (
    <Item>
        <NumberText>
            <ClickAble href="tel:+31203449480">020 344 9480</ClickAble>
        </NumberText>
    </Item>
);

const Item = styled.div`
    flex-direction: row;
`;
const NumberText = styled.p`
    font-family: 'Input Mono', monospace;
    font-size: 1.5rem;
`;
const ClickAble = styled.a`
    color: black;
    text-decoration: none;
`;

export default PhoneNumber;
