import styled, { css } from 'styled-components';
import cssCalcBetweenViewport, { cssCalcBetweenViewportHorizontal } from '../../../utils/cssCalcBetweenViewport';
import theme from '../../../constants/theme';

export type TitleVariant = 'huge' | 'large' | 'regular' | 'small';

const headingCss = css`
    margin: 0;
    padding: 0;
    font-weight: 500;
`;

export const titleHugeCss = css`
    font-size: ${cssCalcBetweenViewport(56, 96)};
    line-height: ${cssCalcBetweenViewport(48, 96)};
    letter-spacing: -0.024em;
    font-weight: 500;

    @media screen and (${theme.mediaQueries.vertical}) {
        font-size: ${cssCalcBetweenViewportHorizontal(56, 96)};
        line-height: ${cssCalcBetweenViewportHorizontal(48, 96)};
    }
`;

export const titleLargeCss = css`
    font-size: ${cssCalcBetweenViewport(28, 72)};
    line-height: ${cssCalcBetweenViewport(32, 72)};
    letter-spacing: -0.025em;

    @media screen and (${theme.mediaQueries.vertical}) {
        font-size: ${cssCalcBetweenViewportHorizontal(28, 72)};
        line-height: ${cssCalcBetweenViewportHorizontal(28, 68)};
    }
`;

export const titleRegularCss = css`
    font-size: ${cssCalcBetweenViewport(24, 36)};
    line-height: ${cssCalcBetweenViewport(24, 40)};

    @media screen and (${theme.mediaQueries.vertical}) {
        font-size: ${cssCalcBetweenViewportHorizontal(24, 36)};
        line-height: ${cssCalcBetweenViewportHorizontal(24, 40)};
    }
`;

export const titleSmallCss = css`
    font-size: ${cssCalcBetweenViewport(14, 19)};
    line-height: ${cssCalcBetweenViewport(24, 32)};

    @media screen and (${theme.mediaQueries.vertical}) {
        font-size: ${cssCalcBetweenViewportHorizontal(14, 19)};
        line-height: ${cssCalcBetweenViewportHorizontal(24, 32)};
    }
`;

const getTitleCss = (variant?: TitleVariant) => {
    switch (variant) {
        case 'huge':
            return titleHugeCss;
        case 'large':
            return titleLargeCss;
        case 'small':
            return titleSmallCss;
        case 'regular':
        default:
            return titleRegularCss;
    }
};

export const H1 = styled.h1<{ variant?: TitleVariant }>`
    ${headingCss}
    ${({ variant }) => getTitleCss(variant)};
`;

export const H2 = styled.h2<{ variant?: TitleVariant }>`
    ${headingCss}
    ${({ variant }) => getTitleCss(variant)};
`;

export const H3 = styled.h3<{ variant?: TitleVariant }>`
    ${headingCss}
    ${({ variant }) => getTitleCss(variant)};
`;

export const H4 = styled.h4<{ variant?: TitleVariant }>`
    ${headingCss}
    ${({ variant }) => getTitleCss(variant)};
`;

export const H5 = styled.h5<{ variant?: TitleVariant }>`
    ${headingCss}
    ${({ variant }) => getTitleCss(variant)};
`;

// Body fonts

export type BodyVariant = 'huge' | 'large' | 'regular' | 'tiny' | 'small';

const bodyCss = css`
    margin: 0;
    padding: 0;
`;

export const bodyHugeCss = css`
    font-size: ${cssCalcBetweenViewport(19, 28)};
    line-height: ${cssCalcBetweenViewport(24, 40)};
    font-weight: 100;

    @media screen and (${theme.mediaQueries.vertical}) {
        font-size: ${cssCalcBetweenViewportHorizontal(20, 28)};
        line-height: ${cssCalcBetweenViewportHorizontal(32, 36)};
    }
`;

export const bodyLargeCss = css`
    font-size: ${cssCalcBetweenViewport(16, 24)};
    line-height: ${cssCalcBetweenViewport(24, 32)};
    font-weight: 300;

    @media screen and (${theme.mediaQueries.vertical}) {
        font-size: ${cssCalcBetweenViewportHorizontal(16, 24)};
        line-height: ${cssCalcBetweenViewportHorizontal(24, 32)};
    }
`;

export const bodyRegularCss = css`
    font-size: ${cssCalcBetweenViewport(14, 19)};
    line-height: ${cssCalcBetweenViewport(24, 32)};
    font-weight: 100;
`;

export const bodySmallCss = css`
    font-size: ${cssCalcBetweenViewport(14, 17)};
    line-height: ${cssCalcBetweenViewport(24, 28)};
    font-weight: 100;
`;

export const bodyTinyCss = css`
    font-size: ${cssCalcBetweenViewport(13, 13)};
    line-height: ${cssCalcBetweenViewport(20, 20)};
`;

export const getBodyCss = (variant?: BodyVariant) => {
    switch (variant) {
        case 'huge':
            return bodyHugeCss;
        case 'large':
            return bodyLargeCss;
        case 'small':
            return bodySmallCss;
        case 'tiny':
            return bodyTinyCss;
        case 'regular':
        default:
            return bodyRegularCss;
    }
};

export const Body = styled.p<{ variant?: BodyVariant }>`
    ${bodyCss}
    ${({ variant }) => getBodyCss(variant)};
`;

// Lists

export type ListVariant = 'regular' | 'small';

const listCss = css`
    padding-left: 2em;
    list-style: bullets;
    font-weight: 400;

    & > li + li {
        margin-top: 1em;
    }
`;

export const listSmallCss = css`
    font-size: ${cssCalcBetweenViewport(13, 17)};
    line-height: ${cssCalcBetweenViewport(20, 28)};
`;

export const listRegularCss = css`
    font-size: ${cssCalcBetweenViewport(16, 24)};
    line-height: ${cssCalcBetweenViewport(24, 32)};
`;

const getListCss = (variant?: ListVariant) => {
    switch (variant) {
        case 'small':
            return listSmallCss;
        case 'regular':
        default:
            return listRegularCss;
    }
};

export const List = styled.ul<{ variant?: ListVariant }>`
    ${listCss}
    ${({ variant }) => getListCss(variant)};
`;

// Interactive fonts

export const actionCss = css`
    margin: 0;
    padding: 0;
    font-size: ${cssCalcBetweenViewport(12, 14)};
    line-height: ${cssCalcBetweenViewport(12, 14)};
    font-weight: 800;
`;

export const Action = styled.span`
    ${actionCss}
`;

// mono fonts

export const monoCss = css`
    font-family: 'Input Mono', monospace;
    font-size: ${cssCalcBetweenViewport(12, 14)};
    line-height: ${cssCalcBetweenViewport(12, 14)};
    font-weight: 400;
    text-decoration: none;
`;

export const Mono = styled.span`
    ${monoCss}
`;
