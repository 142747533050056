import { Link } from 'gatsby';
import React, { useContext } from 'react';
import styled from 'styled-components';
import theme from '../constants/theme';
import { SiteContext } from '../contexts/SiteContext';
import { Mono } from './ds/primitive/Typography';

const LanguageSwitcher: React.FC = () => {
    const site = useContext(SiteContext);
    return (
        <Container>
            <Mono>
                <LangLink to="/" active={!site || site === 'nl'}>
                    NL
                </LangLink>
            </Mono>
            <Mono>
                <LangLink to="/en/" active={site === 'en'}>
                    EN
                </LangLink>
            </Mono>
        </Container>
    );
};

const Container = styled.div`
    color: ${theme.colors.white};
    display: inline-block;

    @media screen and (${theme.mediaQueries.collapsedMenu}) {
        display: none;
    }

    & > * + * {
        --gap: 0.8rem;
        margin-left: var(--gap);

        &:before {
            content: '';
            display: inline-block;
            width: 0;
            height: 1.6rem;
            border-left: 0.1rem solid ${theme.colors.tundora};
            margin-right: var(--gap);
            vertical-align: -22%;
        }
    }
`;

const LangLink = styled(Link)<{ active: boolean }>`
    &,
    &:visited {
        color: ${({ active }) => (active ? theme.colors.white : theme.colors.tundora)};
        font-weight: ${({ active }) => active && 600};
    }
`;

export default LanguageSwitcher;
