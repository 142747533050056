import React, { HTMLAttributes, SFC } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../constants/theme';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';

export type TitleVariant = 'huge' | 'large' | 'medium' | 'small' | 'tiny' | 'narration';

interface Props extends HTMLAttributes<{}> {
    variant?: TitleVariant;
    as?: keyof JSX.IntrinsicElements;
}

const elementMap: { [k in TitleVariant]: keyof JSX.IntrinsicElements } = {
    huge: 'h1',
    large: 'h2',
    medium: 'h3',
    small: 'h4',
    tiny: 'h5',
    narration: 'h6',
};

export const hugeCss = css`
    font-size: 16rem;
    line-height: 0.98;
    letter-spacing: -0.05em;

    @media (max-width: ${theme.mediaQueries.width.xxl}), (max-height: ${theme.mediaQueries.height.xxl}) {
        font-size: 12rem;
    }
    @media (max-width: ${theme.mediaQueries.width.xl}), (max-height: ${theme.mediaQueries.height.xl}) {
        font-size: 11rem;
    }
    @media (max-width: ${theme.mediaQueries.width.l}), (max-height: ${theme.mediaQueries.height.l}) {
        font-size: 10rem;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        font-size: 7rem;
    }
    @media (max-width: ${theme.mediaQueries.width.s}), (max-height: ${theme.mediaQueries.height.s}) {
        font-size: 3.5rem;
    }
`;

export const largeCss = css`
    font-weight: 500;
    font-size: 7.2rem;
    line-height: 1;
    max-width: 100rem;

    @media (max-width: ${theme.mediaQueries.width.xxl}) and (max-height: ${theme.mediaQueries.height.xxl}) {
        font-size: 5.6rem;
    }
    @media (max-width: ${theme.mediaQueries.width.xl}) and (max-height: ${theme.mediaQueries.height.xl}) {
        font-size: 5.2rem;
    }
    @media (max-width: ${theme.mediaQueries.width.l}) and (max-height: ${theme.mediaQueries.height.l}) {
        font-size: 5.2rem;
    }
    @media (max-width: ${theme.mediaQueries.width.m}) and (max-height: ${theme.mediaQueries.height.m}) {
        font-size: 2.4rem;
    }
`;

export const mediumCss = css`
    font-size: 2.4rem;
    line-height: 1.2;

    @media screen and (min-height: ${theme.mediaQueries.height.m}) {
        font-size: 3rem;
    }
    @media screen and (min-height: ${theme.mediaQueries.width.s}) {
        font-size: 2.6rem;
    }
    @media screen and (min-height: ${theme.mediaQueries.height.l}) {
        font-size: 3.6rem;
    }
    @media screen and (min-height: ${theme.mediaQueries.height.xl}) {
        font-size: 4.4rem;
    }
    @media (max-width: ${theme.mediaQueries.width.m}), (max-height: ${theme.mediaQueries.height.m}) {
        font-size: 3rem;
    }
    @media (max-width: ${theme.mediaQueries.width.s}), (max-height: ${theme.mediaQueries.height.s}) {
        font-size: 2.5rem;
    }
`;

export const smallCss = css`
    font-weight: 500;
    font-size: 2.4rem;
    letter-spacing: 0;
    line-height: 1;
    @media screen and (max-width: ${theme.mediaQueries.width.m}) {
        font-size: 2.4rem;
    }
`;

export const tinyCss = css`
    font-family: 'Input Mono', monospace;
    font-size: 1.5rem;
    line-height: 1.2;
    @media screen and (max-width: ${theme.mediaQueries.width.s}) {
        font-size: 1.2rem;
    }
`;

export const narrationCss = css`
    ${tinyCss};
    font-weight: 500;
    line-height: 1.2;
`;

const getCss = (variant: TitleVariant) => {
    switch (variant) {
        case 'huge':
            return hugeCss;
        case 'large':
            return largeCss;
        case 'medium':
            return mediumCss;
        case 'small':
            return smallCss;
        case 'tiny':
            return tinyCss;
        case 'narration':
            return narrationCss;
        default:
            // ensures this switch is exhaustive
            throw new UnreachableCaseError(variant);
    }
};

const TitleInner = styled.h1<Props>`
    font-weight: 500;
    margin: 0;
    white-space: pre-line;
    letter-spacing: -0.02em;
    ${({ variant = 'medium' }) => getCss(variant)}
    strong {
        display: block;
    }
`;

const Title: SFC<Props> = ({ variant = 'medium', children, as = elementMap[variant], ...rest }) => {
    return (
        <TitleInner as={as} variant={variant} {...rest}>
            {children}
        </TitleInner>
    );
};

export default Title;
