const theme = {
    colors: {
        black: '#000',
        codGray: '#141414',
        athensGray: '#F2F5F7',
        shark: '#191E21',
        tundora: '#404040',
        stone: '#76797A',
        royalBlue: '#4948EE',
        powderBlue: '#BCDDEA',
        dustyGray: '#9B9B9B',
        gorse: '#FFF251',
        lilac: '#FFA5AC',
        cosmos: '#FFD2D6',
        alablaster: '#F8F8F8',
        white: '#fff',
        alto: '#D8D8D8',
        brightRed: '#df141a',
    },
    mediaQueries: {
        width: {
            s: '812px',
            m: '1024px',
            l: '1440px',
            xl: '1920px',
            xxl: '2300px',
        },
        height: {
            xs: '335px',
            s: '375px',
            m: '768px',
            l: '900px',
            xl: '1200px',
            xxl: '1300px',
        },
        horizontal: 'min-width: 897px',
        vertical: 'max-width: 899px',
        collapsedMenu: 'max-width: 1246px',
        expandedMenu: 'min-width: 1246px',
    },
};

export default theme;
