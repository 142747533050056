import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

export const ContentStyle = css`
    font-weight: 700;
    position: relative;
    text-decoration: none;
    padding-bottom: 1rem;
    line-height: 2.5;
    display: inline;
`;

export const ExternalContentLink = styled.a`
    color: ${({ color }) => (color ? color : 'currentColor')};
    border-bottom: 2px ${({ color }) => (color ? color : 'currentColor')} solid;
`;

const ContentLink = styled(Link)`
    color: ${({ color }) => (color ? color : 'currentColor')};
    border-bottom: 2px ${({ color }) => (color ? color : 'currentColor')} solid;
`;

export default ContentLink;
