import React, { FC, useContext } from 'react';
import OberonLogo from '../molecules/OberonLogo';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme from '../../constants/theme';
import CloseButton from '../atoms/CloseButton';
import ReactModal from 'react-modal';
import MobileMenuLink from '../molecules/MobileMenuLink';
import { NotFoundFragment_linkList } from '../../entities/operationResults';
import MobileLanguageSwitcher from '../MobileLanguageSwitcher';
import { Body } from '../ds/primitive/Typography';
import { SiteContext } from '../../contexts/SiteContext';

interface Props {
    isOpen: boolean;
    close: () => void;
    menuData: NotFoundFragment_linkList[];
}

ReactModal.setAppElement('#___gatsby');

const MobileMenu: FC<Props> = ({ isOpen, close, menuData }) => {
    const site = useContext(SiteContext);

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => close()}
            shouldReturnFocusAfterClose={false}
            closeTimeoutMS={250}
        >
            <Container>
                <Header>
                    <HomeLink to={site ? `/${site}/` : '/'} onClick={() => close()}>
                        <Logo />
                    </HomeLink>
                    <Close onClick={() => close()} />
                </Header>
                <Menu>
                    <MobileMenuLink close={() => close()} isHome />
                    {menuData &&
                        menuData.map((item, i: number) => (
                            <MobileMenuLink key={i} close={() => close()} menuItem={item} />
                        ))}
                </Menu>
                <Footer>
                    <div>
                        <Element variant={'tiny'}>
                            <Clickable href="tel:+31203449480">+31 (0)20 344 9480</Clickable>
                        </Element>
                        <Element variant={'tiny'}>
                            <Clickable href="mailto:contact@oberon.nl">contact@oberon.nl</Clickable>
                        </Element>
                    </div>
                    <MobileLanguageSwitcher />
                </Footer>
            </Container>
        </ReactModal>
    );
};

const HomeLink = styled(Link)`
    filter: invert(1);
`;

const Logo = styled(OberonLogo)`
    height: auto;
`;

const Close = styled(CloseButton)`
    height: 40px;
    width: 40px;
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background-color: ${theme.colors.black};
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Menu = styled.div`
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

const Footer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: inherit;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const WhiteLink = `
    color: white;
    text-decoration: none;

    &:active {
        color: ${theme.colors.gorse}
    }
`;

const Element = styled(Body)`
    ${WhiteLink}
`;

const Clickable = styled.a`
    &,
    &:visited {
        ${WhiteLink}
    }
`;

export default MobileMenu;
