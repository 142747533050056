import React, { FC, SVGAttributes } from 'react';

interface Props extends SVGAttributes<SVGElement> {
    black?: boolean;
}

const OberonIcon: FC<Props> = ({ black, ...props }) => (
    <svg {...props} width="32px" height="23px" viewBox="0 0 32 23" version="1.1">
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g
                id="ORGANISM-/-navigation-/-dark-alt-over-ons-S-"
                transform="translate(-61.000000, -5.000000)"
                fill={black ? '#000' : '#FFF'}
            >
                <g id="Fill-6" transform="translate(61.000000, 5.000000)">
                    <path
                        d="M9.51351992,3.5275491 C13.8159456,-0.373905154 19.2059406,-1.17080765 22.1092056,1.79880848 L30.2414386,10.1166344 C33.1446505,13.0862505 32.3655393,18.599273 28.5511336,23 L9.51351992,3.5275491 Z M3.50576221,0.0321570093 L22.8573254,19.2953556 C18.4840145,23.1548416 13.0049864,23.9432902 10.0537979,21.0056313 L1.78758711,12.7771126 C-1.16360144,9.83945374 -0.371482071,4.38555187 3.50576221,0.0321570093 Z"
                        id="Fill-5"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default OberonIcon;
