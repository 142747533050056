import { Link } from 'gatsby';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import theme from '../constants/theme';
import { SiteContext } from '../contexts/SiteContext';
import { Action } from './ds/primitive/Typography';

const MobileLanguageSwitcher: React.FC = () => {
    const site = useContext(SiteContext);
    const en = useMemo(() => site === 'en', [site]);
    const nl = useMemo(() => !site || site === 'nl', [site]);

    return (
        <Container>
            {en && (
                <Action>
                    <LangLink to="/">
                        Nederlandse site
                    </LangLink>
                </Action>
            )}
            {nl && (
                <Action>
                    <LangLink to="/en/">
                        English site
                    </LangLink>
                </Action>
            )}
        </Container>
    );
};

const Container = styled.div`
    color: ${theme.colors.white};
    display: inline-block;
`;

const LangLink = styled(Link)`
    &,
    &:visited {
        color: ${theme.colors.white};
        border-bottom: 1px solid currentColor;
    }
`;

export default MobileLanguageSwitcher;
