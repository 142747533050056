import ApolloClient from 'apollo-client';
import promise from 'es6-promise';
import 'isomorphic-fetch';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

promise.polyfill();

export default () => {
    const client = new ApolloClient({
        link: new HttpLink({
            uri: process.env.GATSBY_GRAPHQL_URL,
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_GRAPHQL_TOKEN}`,
            },
        }),
        cache: new InMemoryCache(),
    });

    return client;
};
