import theme from '../../constants/theme';

interface ParallaxEl {
    element: HTMLElement;
    offsetX: number;
    strength: number;
}

interface InViewEl {
    element: HTMLElement;
    offsetX: number;
    width: number;
    callback: inViewCallback;
    inView: boolean | null;
}

export interface ScrollPosition {
    x: number;
    y: number;
}

export interface WindowSize {
    width: number;
    height: number;
}

export type offsetChangeCallback = (scrollPosition: ScrollPosition, windowSize: WindowSize) => void;
export type inViewCallback = (inView: boolean) => void;

class ScrollController {
    static PARALLAX_VALUE = 50;
    static PARALLAX_ENABLED =
        typeof window !== 'undefined' && window.matchMedia(`(${theme.mediaQueries.horizontal})`).matches;

    private scrollPosition: ScrollPosition = { x: 0, y: 0 };
    private prevScrollPosition: ScrollPosition = { x: 0, y: 0 };
    private windowSize: WindowSize = { width: 0, height: 0 };

    private parallaxEls: ParallaxEl[] = [];
    private callbacks: offsetChangeCallback[] = [];
    private inViewCheckEls: InViewEl[] = [];

    constructor() {
        if (!ScrollController.PARALLAX_ENABLED) {
            return;
        }

        requestAnimationFrame(this.tick());

        this.addListeners();
    }

    onWheel = (scrollX: number) => {
        requestAnimationFrame(this.tick(scrollX));
    };

    onScroll = () => {
        requestAnimationFrame(this.tick());
    };

    addParallax(element: HTMLElement, strength: number, callback?: inViewCallback) {
        if (!ScrollController.PARALLAX_ENABLED) {
            return;
        }

        this.parallaxEls = [
            ...this.parallaxEls,
            { element, strength, offsetX: this.scrollPosition.x + element.getBoundingClientRect().left },
        ];

        requestAnimationFrame(this.tick());
    }

    removeParallax(element: HTMLElement) {
        if (!window) {
            return;
        }

        this.parallaxEls = this.parallaxEls.filter((el) => el.element !== element);
    }

    addCallback(callback: offsetChangeCallback) {
        this.callbacks = [...this.callbacks, callback];
    }

    removeCallback(callback: offsetChangeCallback) {
        this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    }

    removeListeners() {
        window.removeEventListener('resize', this.onResize);
    }

    private easeInQuad = (t: number) => t * t;

    private tick = (scrollX = window.scrollX) => (time: number) => {
        this.scrollPosition.x = scrollX;

        if (this.scrollPosition.x !== this.prevScrollPosition.x) {
            // parallax
            this.parallaxEls.map((pEl) => this.parallax(pEl));

            // in view checks
            this.inViewCheckEls.map((el) => this.inView(el));

            // fire offset change callbacks
            this.callbacks.map((cb) =>
                cb(
                    {
                        x: this.scrollPosition.x,
                        y: this.scrollPosition.x,
                    },
                    {
                        width: this.windowSize.width,
                        height: this.windowSize.height,
                    }
                )
            );
        }

        this.prevScrollPosition.x = this.scrollPosition.x;
    };

    private parallax = (parallaxElement: ParallaxEl) => {
        if (
            this.scrollPosition.x + this.windowSize.width > parallaxElement.offsetX &&
            parallaxElement.offsetX > this.scrollPosition.x
        ) {
            const progress = (parallaxElement.offsetX - this.scrollPosition.x) / this.windowSize.width;
            const translateX = ScrollController.PARALLAX_VALUE * this.easeInQuad(progress) * parallaxElement.strength;

            parallaxElement.element.style.transform = `translate3d(${translateX}%, 0, 0)`;
        }
    };

    private inView = (inViewCheckElement: InViewEl) => {
        const inView =
            this.scrollPosition.x + this.windowSize.width > inViewCheckElement.offsetX &&
            inViewCheckElement.offsetX + inViewCheckElement.width > this.scrollPosition.x;

        if (inView !== inViewCheckElement.inView) {
            inViewCheckElement.callback(inView);
            inViewCheckElement.inView = inView;
        }
    };

    private onResize = () => {
        this.windowSize.height = window.innerHeight;
        this.windowSize.width = window.innerWidth;

        // update offsets

        this.parallaxEls = this.parallaxEls.map(({ element, strength }) => {
            element.style.transform = '';
            return {
                element,
                strength,
                offsetX: this.scrollPosition.x + element.getBoundingClientRect().left,
            };
        });

        this.inViewCheckEls = this.inViewCheckEls.map(({ element, callback }) => ({
            element,
            callback,
            offsetX: this.scrollPosition.x + element.getBoundingClientRect().left,
            width: element.getBoundingClientRect().width,
            inView: null,
        }));

        requestAnimationFrame(this.tick());
    };

    private addListeners = () => {
        this.windowSize.height = window.innerHeight;
        this.windowSize.width = window.innerWidth;

        window.addEventListener('resize', this.onResize);
        window.addEventListener('scroll', this.onScroll);
    };
}

export default new ScrollController();
