import React, { FC, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../../constants/theme';
import { useOffsetPosition } from '../../hooks/useOffsetPostion';
import OberonIcon from '../atoms/OberonIcon';

interface Props {
    className?: string;
    black?: boolean;
}

const OberonLogo: FC<Props> = ({ className, black }) => {
    const [logoFocused, setLogoFocused] = useState(false);
    const [logoFadeOut, setLogoFadeOut] = useState(false);

    useOffsetPosition((scroll, windowSize) => {
        if (scroll.x > 20 && !logoFocused) {
            setLogoFadeOut(true);
        } else {
            setLogoFadeOut(false);
        }
    });
    return (
        <Wrapper
            onMouseEnter={() => {
                setLogoFocused(true);
                setLogoFadeOut(false);
            }}
            onMouseLeave={() => {
                setLogoFocused(false);
            }}
            className={className}
        >
            <StyledIcon black={black} style={{ zIndex: 11 }} viewBox="0 0 32 23" version="1.1">
                <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
                    <g
                        id="ORGANISM-/-navigation-/-dark-alt-over-ons-S-"
                        transform="translate(-61.000000, -5.000000)"
                        fill={black ? '#000' : '#FFF'}
                    >
                        <g id="Fill-6" transform="translate(61.000000, 5.000000)">
                            <path
                                d="M9.51351992,3.5275491 C13.8159456,-0.373905154 19.2059406,-1.17080765 22.1092056,1.79880848 L30.2414386,10.1166344 C33.1446505,13.0862505 32.3655393,18.599273 28.5511336,23 L9.51351992,3.5275491 Z M3.50576221,0.0321570093 L22.8573254,19.2953556 C18.4840145,23.1548416 13.0049864,23.9432902 10.0537979,21.0056313 L1.78758711,12.7771126 C-1.16360144,9.83945374 -0.371482071,4.38555187 3.50576221,0.0321570093 Z"
                                id="Fill-5"
                            />
                        </g>
                    </g>
                </g>
            </StyledIcon>
            <TextWrap logoFocused={logoFocused} logoFadeOut={logoFadeOut}>
                <StyledText viewBox="0 0 123 15" version="1.1">
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g
                            id="ORGANISM-/-navigation-/-dark-alt-S"
                            transform="translate(-108.000000, -8.000000)"
                            fill={black ? '#000' : '#FFF'}
                        >
                            <g id="Group-14" transform="translate(61.000000, 5.000000)">
                                <g id="oberon" transform="translate(47.000000, 3.000000)">
                                    <path
                                        d="M7.33967455,2.24997451 C9.96386604,2.24997451 12.1808782,3.55056961 12.1808782,5.08968747 L12.1808782,10.2420226 C12.1808782,10.4933612 12.1136157,10.7445938 11.9933489,10.9907955 L13.3370617,12.3333333 C13.792846,11.6798848 14.025641,10.9764439 14.025641,10.2420226 L14.025641,5.08968747 C14.025641,2.46403067 11.088724,0.407407407 7.33967455,0.407407407 C5.58146784,0.407407407 3.93160199,0.86575875 2.69230769,1.69794049 L4.03930679,3.04381467 C4.9546386,2.53335267 6.12248306,2.24997451 7.33967455,2.24997451"
                                        id="Fill-14"
                                    />
                                    <path
                                        d="M7.63810409,13.0454213 C5.01254959,13.0454213 2.7944389,11.7441906 2.7944389,10.2043206 L2.7944389,5.04946751 C2.7944389,4.80044335 2.86115298,4.55104831 2.97930797,4.30700463 L1.63399568,2.96296296 C1.18073235,3.6159361 0.948717949,4.31765439 0.948717949,5.04946751 L0.948717949,10.2043206 C0.948717949,12.8312605 3.88716043,14.8888889 7.63810409,14.8888889 C9.39430726,14.8888889 11.0421663,14.4314792 12.2820513,13.601381 L10.9336631,12.2542133 C10.017962,12.7626464 8.85258678,13.0454213 7.63810409,13.0454213"
                                        id="Fill-16"
                                    />
                                    <path
                                        d="M35.2927939,7.92764687 C36.1046502,7.2023608 36.5685759,6.16675974 36.5685759,5.07055699 C36.5685759,2.96889285 34.8786901,1.25925926 32.8022454,1.25925926 L22.7435897,1.25925926 L22.7435897,14.8888889 L32.8022454,14.8888889 C34.9469484,14.8888889 36.6923077,13.1221995 36.6923077,10.9506392 C36.6923077,9.77615932 36.183224,8.68022519 35.2927939,7.92764687 Z M24.6532846,8.88185472 L32.8022454,8.88185472 C33.8944324,8.88185472 34.7826128,9.80989855 34.7826128,10.9506392 C34.7826128,12.0913262 33.8944324,13.0195849 32.8022454,13.0195849 L24.6532846,13.0195849 L24.6532846,8.88185472 Z M32.8022454,7.01260447 L24.6532846,7.01260447 L24.6532846,3.12850951 L32.8022454,3.12850951 C33.8260095,3.12850951 34.6588261,3.99981985 34.6588261,5.07055699 C34.6588261,6.14129414 33.8260095,7.01260447 32.8022454,7.01260447 Z"
                                        id="Fill-6"
                                    />
                                    <path
                                        d="M55.5757807,3.12850951 L56.6503976,3.12850951 C57.1825767,3.12850951 57.6153846,2.70924043 57.6153846,2.19391125 C57.6153846,1.67852833 57.1825767,1.25925926 56.6503976,1.25925926 L55.5757807,1.25925926 L45.4102564,1.25925926 L45.4102564,14.8888889 L55.5757807,14.8888889 L56.6258875,14.8888889 C57.1580666,14.8888889 57.5908191,14.4695661 57.5908191,13.9542906 C57.5908191,13.4389077 57.1580666,13.0196386 56.6258875,13.0196386 L55.5757807,13.0196386 L47.340175,13.0196386 L47.340175,8.89077306 L54.7255252,8.89077306 C55.2577598,8.89077306 55.6905122,8.47139653 55.6905122,7.95612107 C55.6905122,7.44079188 55.2577598,7.02152281 54.7255252,7.02152281 L47.340175,7.02152281 L47.340175,3.12850951 L55.5757807,3.12850951 Z"
                                        id="Fill-18"
                                    />

                                    <path
                                        d="M78.5384615,5.07059569 C78.5384615,2.96899356 76.8702012,1.25925926 74.8203285,1.25925926 L65.4615385,1.25925926 L65.4615385,13.9542406 C65.4615385,14.4695677 65.8843724,14.8888889 66.4041126,14.8888889 C66.9239611,14.8888889 67.3469034,14.4695677 67.3469034,13.9542406 L67.3469034,8.8818784 L72.6874708,8.8818784 L76.2276084,14.4526445 C76.4010537,14.725727 76.6995184,14.8888889 77.0251753,14.8888889 C77.2031707,14.8888889 77.3766161,14.8391398 77.5272026,14.7450679 C77.7402446,14.611777 77.8880686,14.4042385 77.9438073,14.1608657 C77.9996001,13.9174392 77.9561575,13.666921 77.821713,13.4558367 L74.9140389,8.88080391 C76.9209564,8.82965801 78.5384615,7.14012419 78.5384615,5.07059569 M74.8203285,7.0125818 L67.3469034,7.0125818 L67.3469034,3.12860959 L74.8203285,3.12860959 C75.8311,3.12860959 76.6532049,3.99986277 76.6532049,5.07059569 C76.6532049,6.14132862 75.8311,7.0125818 74.8203285,7.0125818"
                                        id="Fill-8"
                                    />
                                    <path
                                        d="M93.6320314,2.25400068 C96.2622628,2.25400068 98.4843246,3.55743769 98.4843246,5.09997173 L98.4843246,10.2635652 C98.4843246,10.5053689 98.4193511,10.7468012 98.3082108,10.9838814 L99.6587883,12.3333333 C100.104093,11.6856219 100.333333,10.990038 100.333333,10.2635652 L100.333333,5.09997173 C100.333333,2.46852458 97.3896566,0.407407407 93.6320314,0.407407407 C91.8816252,0.407407407 90.2392779,0.861505991 89,1.68478524 L90.3524901,3.03609473 C91.2663692,2.53396445 92.42447,2.25400068 93.6320314,2.25400068"
                                        id="Fill-10"
                                    />
                                    <path
                                        d="M93.0590011,13.0493523 C90.4394079,13.0493523 88.2262887,11.7508964 88.2262887,10.2142571 L88.2262887,5.07039626 C88.2262887,4.81175192 88.2948104,4.5526846 88.4223296,4.29969744 L87.0845422,2.96296296 L87.0798859,2.96951895 C86.6190179,3.62606955 86.3846154,4.33300609 86.3846154,5.07039626 L86.3846154,10.2142571 C86.3846154,12.8356482 89.3165512,14.8888889 93.0590011,14.8888889 C94.8189245,14.8888889 96.4708005,14.4289122 97.7103293,13.5932351 L97.7179487,13.5881066 L96.375558,12.2464551 C95.4578432,12.7633209 94.2824973,13.0493523 93.0590011,13.0493523"
                                        id="Fill-12"
                                    />

                                    <path
                                        d="M122.04422,1.30885038 C121.517174,1.30885038 121.08844,1.72420261 121.08844,2.23453354 L121.08844,12.0569806 C119.782468,10.6755061 111.14041,1.53323423 111.065714,1.45576141 C110.970696,1.35631312 110.854807,1.25925926 110.571456,1.25925926 L109.051282,1.25925926 L109.051282,13.9136678 C109.051282,14.4239455 109.479906,14.8392978 110.007062,14.8392978 C110.534108,14.8392978 110.962787,14.4239455 110.962787,13.9136678 L110.962787,4.09084832 L120.988809,14.6963242 C121.051696,14.7538968 121.199276,14.8888889 121.479221,14.8888889 L123,14.8888889 L123,2.23453354 C123,1.72420261 122.571322,1.30885038 122.04422,1.30885038"
                                        id="Fill-20"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </StyledText>
            </TextWrap>
        </Wrapper>
    );
};

export default OberonLogo;

const logoFadeOutAnimation = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

const logoEntry = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const StyledIcon = styled(OberonIcon)`
    margin-right: 1rem;
    animation: ${logoEntry} 0.2s forwards ease-in-out;
    width: 3.2rem;
    height: 2.3rem;

    @media screen and (${theme.mediaQueries.vertical}) {
        width: 2rem;
        height: 1.4375rem;
    }
`;

const StyledText = styled.svg`
    width: 12.3rem;
    height: 1.5rem;

    @media screen and (${theme.mediaQueries.vertical}) {
        width: 10rem;
        height: 1.2rem;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    @media screen and (${theme.mediaQueries.vertical}) {
        height: auto;
        filter: invert(1);
    }
`;

const TextWrap = styled.div<{ logoFocused: boolean; logoFadeOut: boolean }>`
    animation: ${({ logoFadeOut }) => (logoFadeOut ? logoFadeOutAnimation : logoEntry)} 0.4s forwards ease-out;
`;
