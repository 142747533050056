import styled from 'styled-components';
import theme from '../../constants/theme';
import { smallCss } from './Title';

export default styled.button<{ isDisabled?: boolean; isHidden?: boolean }>`
    &,
    &:visited {
        background: ${theme.colors.codGray};
        border: none;
        border-radius: 4.5rem;
        color: ${theme.colors.white};
        padding: 0 4rem;
        font-family: inherit;
        ${smallCss};
        line-height: 6.4rem;
        transition: opacity 250ms;
        opacity: ${({ isDisabled, isHidden }) => (isHidden ? 0 : isDisabled ? 0.5 : 1)};

        @media screen and (min-height: ${theme.mediaQueries.height.m}) {
            line-height: 6.4rem;
        }

        @media screen and (min-height: ${theme.mediaQueries.height.l}) {
            line-height: 6.4rem;
        }

        @media screen and (min-height: ${theme.mediaQueries.height.xl}) {
            line-height: 6.4rem;
        }
    }
`;
