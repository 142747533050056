import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../constants/theme';

interface Props {
    isVertical: boolean;
}

const Page: FC<Props> = ({ children, isVertical }) => {
    return <Container isVertical={isVertical}>{children}</Container>;
};

const horizontal = css`
    overflow-x: visible;
    height: 100vh;
    flex-flow: wrap;
    writing-mode: vertical-lr;
    min-width: 100vw;

    & > * {
        height: 100%;
        writing-mode: horizontal-tb;
    }
`;

const vertical = css`
    width: 100vw;
    flex-direction: column;
`;

const Container = styled.main<{ isVertical: boolean }>`
    display: inline-flex;
    z-index: -1;
    background-color: white;
    @media (${theme.mediaQueries.horizontal}) {
        ${(props) => (props.isVertical ? vertical : horizontal)};
    }
    @media (${theme.mediaQueries.vertical}) {
        ${vertical}
        flex-direction: column;
        overflow-x: hidden;
        padding-top: 7rem;
    }
`;

export default Page;
