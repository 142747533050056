import { Link } from 'gatsby';
import React, { FC, Fragment, useContext, useState } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import BodyText from '../atoms/BodyText';
import MenuLink from '../atoms/MenuLink';
import OberonLogo from '../molecules/OberonLogo';
import MobileMenu from './MobileMenu';
import { NotFoundFragment_linkList } from '../../entities/operationResults';
import LanguageSwitcher from '../LanguageSwitcher';
import { monoCss } from '../ds/primitive/Typography';
import { SiteContext } from '../../contexts/SiteContext';

interface Props {
    menuData: {
        entries: Array<{
            linkList: NotFoundFragment_linkList[];
        }>;
    };
    blackLogo?: boolean;
    hideLinks?: boolean;
}

const Menu: FC<Props> = ({ hideLinks, blackLogo, menuData }) => {
    const site = useContext(SiteContext);
    const menuLinks = menuData && menuData.entries && menuData.entries[0] && menuData.entries[0].linkList;
    const [showMobileMenu, setMobileMenu] = useState(false);

    return (
        <Fragment>
            <Nav makeBlack={!hideLinks}>
                <List blackLogo={blackLogo}>
                    <LogoItem>
                        {!blackLogo && (
                            <Link title="Oberon" to={site ? `/${site}/` : '/'}>
                                {!hideLinks ? <OberonLogo /> : <DarkLogo />}
                            </Link>
                        )}
                        {blackLogo && <OberonLogo black />}
                    </LogoItem>
                    {!hideLinks && (
                        <Fragment>
                            {menuLinks &&
                                menuLinks.map((item, i: number) => (
                                    <MenuLink menuItem={item} key={i} />
                                ))}
                            <RightMenu>
                                <ContactButton
                                    href={site ? `/${site}/contact` : '/contact'}
                                    className="menu_contact"
                                >
                                    Contact
                                </ContactButton>
                                <OpenMenu variant={'small'} onClick={() => setMobileMenu(!showMobileMenu)}>
                                    Menu
                                </OpenMenu>
                                <LanguageSwitcher />
                            </RightMenu>
                        </Fragment>
                    )}
                </List>
            </Nav>
            <MobileMenu isOpen={showMobileMenu} close={() => setMobileMenu(!showMobileMenu)} menuData={menuLinks} />
        </Fragment>
    );
};

export default Menu;

const ContactButton = styled.a`
    &,
    &:visited {
        ${monoCss}
        width: 100%;
        background: white;
        color: black;
        padding: 1rem 3rem 1rem;
        border: none;
        border-radius: 2rem;
        cursor: pointer;
        @media screen and (${theme.mediaQueries.vertical}) {
            font-size: 1.4rem;
            padding: 0.8rem 1.75rem;
            background: black;
            color: white;
        }
    }
`;

const DarkLogo = styled(OberonLogo)`
    filter: invert(1);
`;

const RightMenu = styled.li`
    font-size: 0.8rem;

    & > * + * {
        margin-left: 3.2rem;
    }
`;

const OpenMenu = styled(BodyText)`
    display: none;

    &:hover {
        cursor: pointer;
    }

    @media screen and (${theme.mediaQueries.collapsedMenu}) {
        display: inline;
        color: white;
    }
    @media screen and (${theme.mediaQueries.vertical}) {
        color: black;
    }
`;

const LogoItem = styled.li`
    margin-right: auto;
`;

const List = styled.ul<{ blackLogo?: boolean }>`
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0;
    width: 100%;
    ${({ blackLogo }) => (blackLogo ? `justify-content: flex-start;` : `justify-content: flex-end;`)}
    padding: 0 6.4rem;
    @media screen and (${theme.mediaQueries.vertical}) {
        padding: 0 2.5rem;
        display: flex;
        justify-content: space-between;
    }
`;

const Nav = styled.nav<{ makeBlack?: boolean }>`
    position: fixed;
    @supports (-ms-ime-align: auto) {
        background: white;
        ${List} {
            filter: invert(1);
        }
        height: 10.4rem;
        align-content: flex-end;
        padding-top: 0.5vh;
    }
    ${({ makeBlack }) => makeBlack && 'mix-blend-mode: difference;'}
    top: 0;

    @media screen and (${theme.mediaQueries.vertical}) {
        font-size: 0.8rem;
        mix-blend-mode: initial;
        ${({ makeBlack }) => makeBlack && 'background-color: white;'}
        height: 7rem;
    }
    display: flex;
    height: 10.4rem;
    min-height: 6rem;
    width: 100%;
    z-index: 3;
    align-content: center;
`;
