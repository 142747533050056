import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import cookies from 'js-cookie';
import theme from '../constants/theme';
import BodyText from './atoms/BodyText';
import Button from './atoms/Button';
import ContentLink from './atoms/ContentLink';
import Translate from './Translate';

const COOKIE_BANNER = 'cookie_banner_shown';
const MARKETING_COOKIES = 'marketing_cookies';
const COOKIE_DURATION = 365;

const CookieBanner = () => {
    const [isOpen, setIsOpen] = useState(false);

    const cookiesAccepted = useCallback(() => {
        cookies.set(COOKIE_BANNER, '0', { expires: COOKIE_DURATION });
        cookies.set(MARKETING_COOKIES, '1', { expires: COOKIE_DURATION });
        setIsOpen(false);
    }, []);

    const cookiesRefused = useCallback(() => {
        cookies.set(COOKIE_BANNER, '0', { expires: COOKIE_DURATION });
        cookies.set(MARKETING_COOKIES, '0', { expires: COOKIE_DURATION });
        setIsOpen(false);
    }, []);

    useEffect(() => {
        // Turn off marketing cookies if user has not accepted/refused yet
        if (cookies.get(COOKIE_BANNER) !== '0') {
            setIsOpen(true);
            cookies.set(MARKETING_COOKIES, '0', { expires: COOKIE_DURATION });
        } else {
            setIsOpen(false);
        }
    });

    return (
        <CookieOverlay isOpen={isOpen}>
            <Container>
                <TextContainer>
                    <TextField>
                        <Translate id="cookie.description" />
                        <TextLinkClick onClick={() => cookiesRefused()}>
                            <Translate id="cookie.refuse" />
                        </TextLinkClick>
                        . <Translate id="cookie.more" />
                        <TextLink to="/privacy-beleid/">
                            <Translate id="cookie.privacy" />
                        </TextLink>
                        .
                    </TextField>
                </TextContainer>
                <ButtonContainer>
                    <AcceptButton onClick={() => cookiesAccepted()}>
                        <Translate id="cookie.accept" />
                    </AcceptButton>
                </ButtonContainer>
            </Container>
        </CookieOverlay>
    );
};

export default CookieBanner;

const AcceptButton = styled(Button)`
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    color: white;
    padding: 1rem 3rem;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    text-decoration: none;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-basis: 30%;
    justify-content: flex-end;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;

    @media screen and (min-width: ${theme.mediaQueries.width.s}) {
        flex-basis: 40%;
    }

    @media screen and (min-width: ${theme.mediaQueries.width.xl}) {
        flex-basis: 50%;
    }
`;

const Container = styled.div`
    background: ${theme.colors.white};
    padding: 2.4rem 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 11px 17px 0px ${theme.colors.stone};
    align-items: flex-start;

    @media screen and (min-width: ${theme.mediaQueries.width.l}) {
        flex-basis: 55%;
    }

    @media screen and (min-width: ${theme.mediaQueries.width.s}) {
        padding: 1rem 5.6rem;
        flex-direction: row;
        align-items: center;
    }
`;

const CookieOverlay = styled.div<{ isOpen: boolean }>`
    display: ${(props) => (props.isOpen ? 'inline' : 'none')};
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    transition: 0.3s;
    opacity: ${(props) => (props.isOpen ? '100' : '0')};
`;

const TextContainer = styled.div`
    display: flex;
    flex-basis: 70%;

    @media screen and (min-width: ${theme.mediaQueries.width.l}) {
        flex-basis: 60%;
    }

    @media screen and (min-width: ${theme.mediaQueries.width.xl}) {
        flex-basis: 50%;
    }
`;

const TextField = styled(BodyText)`
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
`;

const TextLink = styled(ContentLink)`
    font-weight: 600;
`;
const TextLinkClick = styled.a`
    cursor: pointer;
    font-weight: 600;
    color: ${({ color }) => (color ? color : 'currentColor')};
    border-bottom: 2px ${({ color }) => (color ? color : 'currentColor')} solid;
`;
