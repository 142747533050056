import { Link } from 'gatsby';
import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import theme from '../../constants/theme';
import OberonLogo from '../molecules/OberonLogo';
import PhoneNumber from '../atoms/PhoneNumber';

const CampaignMenu: FC = () => {
    return (
        <Fragment>
            <Nav>
                <List>
                    <LogoItem>
                        <Link title="Oberon" to="/">
                            <OberonLogoLarge black />
                            <OberonLogoSmall />
                        </Link>
                    </LogoItem>
                    <Fragment>
                        <RightMenu>
                            <PhoneNumber />
                        </RightMenu>
                    </Fragment>
                </List>
            </Nav>
        </Fragment>
    );
};

export default CampaignMenu;

const RightMenu = styled.li`
    font-size: 0.8rem;
`;

const LogoItem = styled.li`
    margin-right: auto;
`;

const OberonLogoLarge = styled(OberonLogo)`
    @media (max-width: ${theme.mediaQueries.width.s}) {
        display: none;
    }
`;

const OberonLogoSmall = styled(OberonLogo)`
    @media (min-width: ${theme.mediaQueries.width.s}) {
        display: none;
    }
`;

const List = styled.ul<{}>`
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0;
    width: 100%;
    padding: 0 5rem;

    @media screen and (${theme.mediaQueries.vertical}) {
        padding: 0 2.5rem;
        display: flex;
        justify-content: space-between;
    }
`;

const Nav = styled.nav`
    position: fixed;
    @supports (-ms-ime-align: auto) {
        background: white;
        height: 6vh;
        align-content: flex-end;
        padding-top: 0.5vh;
    }
    top: 0;

    @media screen and (${theme.mediaQueries.vertical}) {
        font-size: 0.8rem;
        mix-blend-mode: initial;
    }
    display: flex;
    height: 10vh;
    min-height: 6rem;
    width: 100%;
    z-index: 3;
    align-content: center;
`;
